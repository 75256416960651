// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Header
\* ------------------------------------------------------------------------ */

.header_inner {
    & {
        padding-bottom: remify($content-padding * 0.4);
        padding-top: remify($content-padding * 0.4);
    }
}

/* fullbleed variant */

.header-block.-fullbleed {
    & {
        padding-bottom: remify($content-padding * 0.4);
        padding-top: remify($content-padding * 0.4);
    }

    > .header_inner {
        padding-bottom: 0;
        padding-top: 0;
    }
}
