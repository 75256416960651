// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Hero
\* ------------------------------------------------------------------------ */

.hero_inner {
    & {
        padding-bottom: remify($content-padding * 0.5);
        padding-top: remify($content-padding * 0.5);
    }
}

.hero_title {
    & {
        padding-left: remify(20);
        padding-right: remify(20);
    }
}

/* fullbleed variant */

.hero-block.-fullbleed {
    & {
        padding-bottom: remify($content-padding * 0.5);
        padding-top: remify($content-padding * 0.5);
    }

    > .hero_inner {
        padding-bottom: 0;
        padding-top: 0;
    }
}
