// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Divider
\* ------------------------------------------------------------------------ */

.divider {
    & {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath fill='%23D6D6D6' d='M28.3,16c0-1-0.8-1.8-1.8-1.8S24.6,15,24.6,16c0,1,0.8,1.8,1.8,1.8C27.5,17.8,28.3,17,28.3,16z M20.3,16 c0-1-0.8-1.8-1.8-1.8c-1,0-1.8,0.8-1.8,1.8c0,1,0.8,1.8,1.8,1.8C19.4,17.8,20.3,17,20.3,16z M12.2,16c0-1-0.8-1.8-1.8-1.8 S8.5,15,8.5,16c0,1,0.8,1.8,1.8,1.8S12.2,17,12.2,16z M4.2,16c0-1-0.8-1.8-1.8-1.8S0.5,15,0.5,16c0,1,0.8,1.8,1.8,1.8 C3.3,17.8,4.2,17,4.2,16z' /%3E%3C/svg%3E%0A");
        background-position: left center;
        background-repeat: repeat-x;
        background-size: remify(21.62746688294134 21.62746688294134);
        border: 0;
        clear: both;
        height: remify(3);
        margin: remify(0 0 9);
        width: 100%;
    }
}

/* Desktop variant */

@media screen and (min-width: $xs-break) {
    .divider {
        background-size: remify(26.5 26.5);
        height: remify(3.675881249999999);
    }
}
