// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Footer
\* ------------------------------------------------------------------------ */

.footer-block {
    @media screen and (max-width: $xl-break) {
        font-size: 1.25vw;
    }
}

.footer_row {
    &.-padded {
        width: calc(100% + #{remify($column-gap * 2)});
    }

    &.-padded > [class*="col-"] {
        padding-left: remify($column-gap * 2, 16);
        padding-right: remify($column-gap * 2, 16);
    }

    &.-padded > [class*="col-"]:first-child {
        padding-left: 0;
    }

    &.-padded > [class*="col-"]:last-child {
        background: none;
        padding-right: 0;
    }
}
