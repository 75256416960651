// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Search Form
\* ------------------------------------------------------------------------ */

.search-form_text {
    & {
        color: $light;
    }
}

.search-form_input {
    & {
        color: $dark_alt;
        margin-bottom: 0;
    }

    &.-checkbox,
    &.-radiobutton {
        background-color: transparent;
        border-color: $light;
        margin-right: remify(9, 12);
    }

    &.-checkbox:checked {
        /* bgImage: ignore */
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath fill='%23FFFFFF' d='M28.6,3.9L10.2,22.3l-6.8-6.8c-0.3-0.3-0.9-0.3-1.2,0l-2,2c-0.3,0.3-0.3,0.9,0,1.2l9.3,9.3c0.3,0.3,0.9,0.3,1.2,0l21-21 c0.3-0.3,0.3-0.9,0-1.2l-2-2C29.4,3.6,28.9,3.6,28.6,3.9z' /%3E%3C/svg%3E%0A");
    }

    &.-radiobutton:checked {
        /* bgImage: ignore */
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath fill='%23FFFFFF' d='M16,0C7.2,0,0,7.2,0,16s7.2,16,16,16s16-7.2,16-16S24.8,0,16,0z' /%3E%3C/svg%3E%0A");
    }

    &:focus,
    &:hover,
    &.-checkbox:focus,
    &.-checkbox:hover,
    &.-radiobutton:focus,
    &.-radiobutton:hover {
        border-color: mix($light, $primary);
    }

    &::placeholder {
        font-style: italic;
        opacity: 1;
    }
}

.search-form_button {
    & {
        background: none;
        color: $primary;
        margin-bottom: 0;
        padding: 0;
        transition: color 0.15s;
    }

    &:focus,
    &:hover {
        background: none;
        color: $primary_alt;
    }
}

.search-form .search-form_pseudo-input {
    & {
        position: relative;
    }

    .search-form_input {
        border-radius: remify(3);
    }

    .search-form_button {
        font-range: $xs-break $xl-break;
        font-size: responsive remify(17.5, 16) remify(28, 16);
        padding: remify(10);
        position: absolute;
        right: 0;
        top: 0;
    }

    .search-form_button::before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath fill='%2358AFB8' d='M17.6,1.9c0,0.9-0.7,1.6-1.6,1.6c-0.9,0-1.6-0.7-1.6-1.6s0.7-1.6,1.6-1.6C16.9,0.3,17.6,1,17.6,1.9z M16,7.2 c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6c0.9,0,1.6-0.7,1.6-1.6C17.6,7.9,16.9,7.2,16,7.2z M16,14.1c-0.9,0-1.6,0.7-1.6,1.6 c0,0.9,0.7,1.6,1.6,1.6c0.9,0,1.6-0.7,1.6-1.6C17.6,14.9,16.9,14.1,16,14.1z M16,21.1c-0.9,0-1.6,0.7-1.6,1.6s0.7,1.6,1.6,1.6 c0.9,0,1.6-0.7,1.6-1.6S16.9,21.1,16,21.1z M16,28c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6c0.9,0,1.6-0.7,1.6-1.6 C17.6,28.8,16.9,28,16,28z' /%3E%3C/svg%3E%0A");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: remify(20 20, 17.5);
        content: "";
        display: block;
        height: 100%;
        opacity: 0.5;
        position: absolute;
        right: 100%;
        top: 0;
        width: remify(2.5, 17.5);
    }
}
