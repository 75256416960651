// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Search Form
\* ------------------------------------------------------------------------ */

.search-form_text {
    & {
        margin-bottom: 0;
    }
}
