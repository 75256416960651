// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Footer
\* ------------------------------------------------------------------------ */

.footer_row {
    &.-padded > [class*="col-"] {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath fill='%23FFFFFF' opacity='0.5' d='M16,28.3c1,0,1.8-0.8,1.8-1.8S17,24.6,16,24.6s-1.8,0.8-1.8,1.8C14.2,27.5,15,28.3,16,28.3z M16,20.3c1,0,1.8-0.8,1.8-1.8 S17,16.7,16,16.7s-1.8,0.8-1.8,1.8C14.2,19.4,15,20.3,16,20.3z M16,12.2c1,0,1.8-0.8,1.8-1.8S17,8.5,16,8.5s-1.8,0.8-1.8,1.8 S15,12.2,16,12.2z M16,4.2c1,0,1.8-0.8,1.8-1.8S17,0.5,16,0.5s-1.8,0.8-1.8,1.8S15,4.2,16,4.2z' /%3E%3C/svg%3E%0A");
        background-position: right remify(-12, 16) top;
        background-repeat: repeat-y;
        background-size: remify(26.5 26.5, 16);
        padding: remify(0 $column-gap, 16);
    }

    &.-padded > [class*="col-"]:first-child {
        padding-left: 0;
    }

    &.-padded > [class*="col-"]:last-child {
        background: none;
        padding-right: 0;
    }
}

.footer_logo {
    & {
        margin-bottom: 0;
        width: remify(120, 16);
    }
}

.footer_menu-list_container {
    .menu-list.-links,
    .menu-list.-social {
        margin-bottom: 0;
    }
}
