// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Navigation
\* ------------------------------------------------------------------------ */

.navigation-block {
    @media screen and (max-width: $xxl-break - (1 / 16)) {
        font-size: 1.171303074670571vw;
    }
}
