// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Banner
\* ------------------------------------------------------------------------ */

.banner_row {
    [class*="col-"].-border {
        border-left: remify(2) solid darken($warning, 5);
        border-top: 0;
        padding-left: remify($column-gap * 0.5, 16);
        padding-top: 0;
    }

    [class*="col-"].-margin {
        margin-top: 0;
    }
}

.banner_button {
    &,
    &:focus,
    &:hover {
        font-size: remify(21, 16);
    }
}

.banner_icon {
    & {
        font-size: remify(42, 16);
    }
}
