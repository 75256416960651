// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Hero
\* ------------------------------------------------------------------------ */

.hero_article-list {
    & {
        padding-left: 0;
        padding-right: 0;
    }
}
