// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Banner
\* ------------------------------------------------------------------------ */

.banner-block {
    & {
        @include layer("banner");
    }
}

.banner_inner {
    & {
        background: $warning;
        margin: 0 auto;
        max-width: remify($site-width);
        padding: remify(($content-padding * 0.3333333333333333) ($content-padding * 0.4166666666666667));
    }
}

.banner_row {
    [class*="col-"].-border {
        border-top: remify(2) solid darken($warning, 5);
        padding-top: remify($column-gap * 0.5, 16);
    }

    [class*="col-"].-margin {
        margin-top: remify($column-gap * 0.5, 16);
    }
}

.banner_button {
    &,
    &:focus,
    &:hover {
        background: $light;
        border-radius: 50%;
        color: $warning;
        font-size: remify(12.5, 16);
        height: remify(25, 12.5);
        margin: 0;
        padding: 0;
        width: remify(25, 12.5);
    }

    @media screen and (max-width: $xs-break - (1 / 16)) {
        position: absolute;
        right: remify($content-padding * 0.4166666666666667);
        top: remify(($content-padding * 0.3333333333333333) + 4);
    }
}

.banner_icon {
    & {
        font-size: remify(25, 16);
    }
}

.banner_title {
    @media screen and (max-width: $xs-break - (1 / 16)) {
        padding-right: calc(#{remify(25, 27)} + #{remify($content-padding * 0.4166666666666667)});
    }
}

.banner_user-content {
    /* stylelint-disable selector-max-type */

    a:not(.button),
    a:not(.button):focus,
    a:not(.button):hover {
        color: $primary;
        font-weight: 700;
        text-decoration: none;
    }

    p {
        font-weight: 400;
    }

    & > :last-child {
        margin-bottom: 0 !important;
    }

    /* stylelint-enable selector-max-type */
}

/* fullbleed variant */

.banner-block.-fullbleed {
    & {
        background: $warning;
        padding: remify(($content-padding * 0.3333333333333333) ($content-padding * 0.4166666666666667));
    }

    > .banner_inner {
        background: none;
        padding: 0;
    }

    > .banner_inner.-fullbleed {
        max-width: none;
    }
}
