// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Menu List
\* ------------------------------------------------------------------------ */

/* ============================ CUSTOM VARIANTS =========================== */

/* submenu variant */

.menu-list.-submenu {
    .menu-list_link {
        background-position: left bottom remify(-11, 24);
        background-size: remify(26.5 26.5);
        padding: remify(18 15 22, 24);
    }
}
