// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Widget
\* ------------------------------------------------------------------------ */

.widget {
    & {
        margin: remify(0 0 30);
    }
}

.widget_content {
    & {
        border-top-width: remify(7);
        padding: remify(($content-padding * 0.4) ($content-padding * 0.6));
    }
}
