// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Button
\* ------------------------------------------------------------------------ */

.button {
    & {
        background: $accent;
        border: 0;
        border-radius: remify(3);
        color: $light;
        cursor: pointer;
        display: inline-block;
        font-display: swap;
        font-family: $heading-font;
        font-range: $xs-break $xl-break;
        font-size: responsive remify(13, 16) remify(16, 16);
        font-weight: 400;
        line-height: remify(16.25, 13);
        margin: remify(0 0 16.25, 13);
        padding: remify(14.65625 40, 13);
        text-decoration: none;
        text-transform: uppercase;
        transition: background-color 0.15s;
    }

    &:focus,
    &:hover {
        background-color: $accent_alt;
    }
}

/* primary variant */

.button.-primary {
    & {
        background-color: $primary;
    }

    &:focus,
    &:hover {
        background-color: $primary_alt;
    }
}

/* tertiary variant */

.button.-tertiary {
    & {
        background-color: $tertiary;
    }

    &:focus,
    &:hover {
        background-color: $tertiary_alt;
    }
}

/* quaternary variant */

.button.-quaternary {
    & {
        background-color: $quaternary;
    }

    &:focus,
    &:hover {
        background-color: $quaternary_alt;
    }
}
