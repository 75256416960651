// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Header
\* ------------------------------------------------------------------------ */

.header-block {
    & {
        @include layer("header");
    }
}

.header_inner {
    & {
        background: $background;
        margin: 0 auto;
        max-width: remify($site-width);
        padding: remify(($content-padding * 0.34) ($content-padding * 0.27));
    }

    &.-narrow {
        max-width: remify($site-width * 0.75);
    }
}

.header_logo {
    & {
        margin: 0 auto;
        max-width: remify(400, 16);
    }
}

/* fullbleed variant */

.header-block.-fullbleed {
    & {
        background: $background;
        padding: remify(($content-padding * 0.34) ($content-padding * 0.27));
    }

    > .header_inner {
        background: none;
        padding: 0;
    }

    > .header_inner.-fullbleed {
        max-width: none;
    }
}
