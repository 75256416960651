// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Footer
\* ------------------------------------------------------------------------ */

.footer-block {
    & {
        @include layer("footer");
    }

    @media screen and (max-width: $xs-break - (1 / 16)) {
        text-align: center;
    }
}

.footer_inner {
    & {
        background: $page_background;
        margin: 0 auto;
        max-width: remify($site-width);
        padding: remify(($content-padding * 0.6) ($content-padding * 0.2));
    }

    &.-narrow {
        max-width: remify($site-width * 0.7714285714285714);
    }
}

.footer_row {
    &.-padded > [class*="col-"] {
        margin-bottom: remify(30, 16);
    }
}

.footer_logo {
    & {
        margin: remify(0 auto 16, 16);
        opacity: 0.5;
        transition: opacity 0.15s;
        width: remify(90, 16);
    }

    &:focus,
    &:hover {
        opacity: 0.75;
    }
}

.footer_menu-list_container {
    .menu-list.-links {
        margin-bottom: remify(16, 12);
    }

    .menu-list.-social {
        margin-bottom: remify(16, 24);
    }
}

.footer_ninja-form_container {
    .user-content_text {
        font-range: $xs-break $xl-break;
        font-size: responsive remify(16, 16) remify(22, 16);
    }
}

/* fullbleed variant */

.footer-block.-fullbleed {
    & {
        background: $page_background;
        padding: remify(($content-padding * 0.6) ($content-padding * 0.2));
    }

    > .footer_inner {
        background: none;
        padding: 0;
    }

    > .footer_inner.-fullbleed {
        max-width: none;
    }
}

/* alt variant */

.footer-block.-alt {
    .footer_inner {
        background: $dark_alt;
        border-bottom: remify(12) solid $background;
        border-top: remify(12) solid $background;
    }
}

/* alt fullbleed variant */

.footer-block.-alt.-fullbleed {
    & {
        background: $dark_alt;
        border-bottom: remify(12) solid $background;
        border-top: remify(12) solid $background;
    }

    > .footer_inner {
        background: none;
        border-bottom: 0;
        border-top: 0;
    }
}
