// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Swiper (http://idangero.us/swiper/)
\* ------------------------------------------------------------------------ */

/* stylelint-disable selector-class-pattern */

// Custom

.swiper-slide {
    & {
        margin-bottom: remify($content-padding * 0.7);
    }
}

.swiper-caption {
    & {
        background: transparentize($background, 0.25);
        border-radius: remify(3);
        bottom: remify($content-padding * 0.5);
        max-width: 100%;
        padding: remify($content-padding * 0.5);
        position: absolute;
        right: remify($content-padding * 0.5);
        width: remify(460);
    }
}

.swiper-button {
    & {
        width: remify(75, 16);
    }
}

.swiper-pagination-bullet {
    & {
        height: remify(10, 16);
        width: remify(10, 16);
    }
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    & {
        margin: remify(5, 16);
    }
}

/* scroller variant */

.swiper-container_container.-scroller {
    & {
        padding: remify(($content-padding * 0.75) ($content-padding * 1.5));
    }
}
