// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Article
\* ------------------------------------------------------------------------ */

/* eventexcerpt variant */

.article.-eventexcerpt {
    & {
        background-size: remify(26.5 26.5);
        padding: remify(0 20 13);
    }
}

/* material variant */

.article.-material {
    & {
        margin-bottom: remify(16, 16);
    }
}
