// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Swiper (http://idangero.us/swiper/)
\* ------------------------------------------------------------------------ */

/* stylelint-disable selector-class-pattern */

// Vendor

@import "../../vendor/_swiper";

// Custom

.swiper-slide {
    & {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        height: auto;
        margin: remify(0 0 ($content-padding * 0.3));
        overflow: hidden;
    }
}

.swiper-picture,
.swiper-picture {
    & {
        flex: none;
    }
}

.swiper-image {
    & {
        border-radius: remify(3);
        display: block;
        width: 100%;
    }

    .swiper-container.-fixedheight & {
        left: 50%;
        min-width: 100%;
        position: relative;
        transform: translateX(-50%);
        width: auto;
    }
}

.swiper-caption {
    & {
        background: $background;
        flex: 1 1 auto;
        padding: remify(($content-padding * 0.12) ($content-padding * 0.12) ($content-padding * 0.15));
    }

    .swiper-container.-fullbleed & .swiper-caption-inner {
        margin: 0 auto;
        max-width: remify($site-width, 16);
    }
}

.swiper-title {
    & {
        color: $tertiary;
        font-size: responsive remify(15, 16) remify(24, 16);
        font-weight: 400;
    }

    @media screen and (max-width: $xs-break - (1 / 16)) {
        text-align: center;
    }
}

.swiper-text {
    @media screen and (max-width: $xs-break - (1 / 16)) {
        text-align: center;
    }
}

.swiper-button {
    & {
        background: none;
        border: 0;
        color: $foreground;
        display: block;
        height: 100%;
        margin: 0;
        opacity: 1;
        padding: 0;
        position: absolute;
        top: 0;
        transition: color 0.15s;
        width: remify(50, 16);
        z-index: 999;
    }

    &.-prev {
        background: none;
        left: 0;
    }

    .swiper-container-rtl &.-prev {
        background: none;
        right: 0;
    }

    &.-next {
        background: none;
        right: 0;
    }

    .swiper-container-rtl &.-next {
        background: none;
        left: 0;
    }

    .swiper-container:hover & {
        opacity: 0.5;
    }

    .swiper-container:hover &:hover,
    .swiper-container:hover &:focus {
        color: $primary;
    }

    .swiper-button-icon {
        font-size: remify(38, 16);
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
    }

    .swiper-button-icon .icon_svg {
        bottom: auto;
    }
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
    & {
        bottom: 0;
    }
}

.swiper-pagination-bullet {
    & {
        background: $quaternary;
        height: remify(7.5, 16);
        opacity: 1;
        transition: background-color 0.15s;
        width: remify(7.5, 16);
    }
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    & {
        margin: remify(3.75, 16);
    }
}

.swiper-pagination-bullet-active {
    & {
        background-color: $primary;
    }
}

/* scroller variant */

.swiper-container_container.-scroller {
    & {
        background: $background;
        border-radius: remify(3);
        padding: remify(($content-padding * 0.5) $content-padding);
        position: relative;
    }

    .swiper-slide {
        margin-bottom: 0;
    }
}
