// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Content
\* ------------------------------------------------------------------------ */

.content-block {
    & {
        @include layer("content");
    }
}

.content_inner {
    & {
        background: $background;
        margin: 0 auto;
        max-width: remify($site-width);
        padding: remify(($content-padding * 0.6) ($content-padding * 0.4));
    }

    &.-narrow {
        max-width: remify($site-width * 0.75);
    }
}

.content_row {
    ._flex > &.-padded.-callouts > [class*="col-"]:last-child {
        padding-right: 0;
    }

    @media screen and (max-width: $xs-break - (1 / 16)) {
        ._flex > &.-padded.-callouts > [class*="col-xs-"] {
            padding-right: 0;
        }
    }
}

/* fullbleed variant */

.content-block.-fullbleed {
    & {
        background: $background;
        padding: remify(($content-padding * 0.6) ($content-padding * 0.4));
    }

    > .content_inner {
        background: none;
        padding: 0;
    }

    > .content_inner.-fullbleed {
        max-width: none;
    }
}

/* alt variant */

.content-block.-alt {
    > .content_inner {
        background: $background_alt;
    }
}

/* alt fullbleed variant */

.content-block.-alt.-fullbleed {
    & {
        background: $background_alt;
    }

    > .content_inner {
        background: none;
    }
}
