// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Colors
\* ------------------------------------------------------------------------ */

$primary: #58AFB8;
$primary_alt: #7CC0C7;

$secondary: #F58436;
$secondary_alt: #F7A267;

$tertiary: #F04C39;
$tertiary_alt: #F47768;

$quaternary: #EEE579;
$quaternary_alt: #F4EEA6;

$accent: #F58436;
$accent_alt: #F7A267;

$light: #FFFFFF;
$light_alt: #EEEEEE;

$dark: #000000;
$dark_alt: #766F66;

$foreground: #373737;
$foreground_alt: #766F66;

$background: #FFFFFF;
$background_alt: #EEEEEE;

$page_background: #58AFB8;
$page_background_alt: #7CC0C7;

$warning: #9F0000;
$warning_alt: #F83636;

/* ------------------------------------------------------------------------ *\
 * Fonts                                                                    *
\* ------------------------------------------------------------------------ */

$heading-font: "Nunito", "Open Sans", "Helvetica", "Arial", sans-serif;
$body-font: "Open Sans", "Helvetica", "Arial", sans-serif;

/* ------------------------------------------------------------------------ *\
 * Widths                                                                   *
\* ------------------------------------------------------------------------ */

$site-width: 1400;
$column-gap: 30;
$content-padding: 50;

/* ------------------------------------------------------------------------ *\
 * Break Points                                                             *
\* ------------------------------------------------------------------------ */

$xxxl-break: 1440em / 16;
$xxl-break: 1366em / 16;
$xl-break: 1280em / 16;
$l-break: 1024em / 16;
$m-break: 960em / 16;
$s-break: 853em / 16;
$xs-break: 768em / 16;
$xxs-break: 640em / 16;
$xxxs-break: 480em / 16;

/* ------------------------------------------------------------------------ *\
 * Layers                                                                   *
\* ------------------------------------------------------------------------ */

$layers: (
    header: 5,
    navigation: 4,
    hero: 3,
    content: 2,
    footer: 1,
);
