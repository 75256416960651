// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Hero
\* ------------------------------------------------------------------------ */

.hero-block {
    & {
        @include layer("hero");
    }
}

.hero_inner {
    & {
        background: $background;
        margin: 0 auto;
        max-width: remify($site-width);
        padding: remify($content-padding * 0.08);
    }

    &.-narrow {
        max-width: remify($site-width * 0.75);
    }
}

.hero_title {
    & {
        align-items: center;
        color: $accent;
        display: flex;
        font-range: $xs-break $xl-break;
        font-size: responsive remify(20, 16) remify(24, 16);
        margin-bottom: remify(11);
        padding: remify(0 15);
    }

    .title_icon {
        flex: 0 0 auto;
        font-size: remify(32, 20);
        margin-right: remify(15, 32);
    }

    .title_icon .icon_svg {
        bottom: auto;
    }
}

.hero_article-list {
    & {
        padding: remify(0 ($content-padding * 0.4));
    }
}

/* fullbleed variant */

.hero-block.-fullbleed {
    & {
        background: $background;
        padding: remify($content-padding * 0.08);
    }

    > .hero_inner {
        background: none;
        padding: 0;
    }

    > .hero_inner.-fullbleed {
        max-width: none;
    }
}
