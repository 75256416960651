// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * User Content
\* ------------------------------------------------------------------------ */

.user-content {
    /* stylelint-disable selector-no-qualifying-type, selector-max-type */

    // images

    img {
        display: block;
        height: auto !important;
        max-width: 100%;
    }

    // alignment

    .alignleft {
        float: left;
        margin-right: remify(25, 16);
        max-width: (100% / 3);
    }

    .alignright {
        float: right;
        margin-left: remify(25, 16);
        max-width: (100% / 3);
    }

    .aligncenter {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    /* stylelint-enable */
}

/* light variant */

.user-content.-light {
    /* stylelint-disable selector-no-qualifying-type, selector-max-type */

    .link,
    .title,
    .text {
        color: $light;
    }

    /* stylelint-enable */
}
