// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Blockquote
\* ------------------------------------------------------------------------ */

.blockquote {
    & {
        border-left: remify(2) solid $accent;
        margin: 0;
        padding: remify(0 0 0 24, 16);
    }
}

/* tablet variant */

@media screen and (min-width: $xs-break) {
    .blockquote {
        padding-left: remify(27, 16);
    }
}
