// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Translate Bar
\* ------------------------------------------------------------------------ */

/* stylelint-disable selector-class-pattern, selector-max-id */

body {
    & {
        top: auto !important;
    }
}

.goog-te-banner-frame {
    & {
        display: none !important;
    }
}

.language-picker__input {
    & {
        cursor: pointer;
        height: 100%;
        left: 0;
        margin: 0;
        opacity: 0;
        padding: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 1;
    }
}

/* hide google translate banner */

[class*="translated-"],
.translated-ltr,
.translated-rtl {
    body {
        top: 0 !important;
    }

    body > .skiptranslate:first-child {
        display: none !important;
    }
}

#google_translate_element,
.goog-te-banner-frame {
    & {
        display: none !important;
    }
}

/* stylelint-enable selector-class-pattern */
