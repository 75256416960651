// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Panel Toggle
\* ------------------------------------------------------------------------ */

.panel-toggle {
    & {
        align-items: center;
        background: $dark_alt;
        border: 0;
        color: $light;
        display: flex;
        font-family: $heading-font;
        font-size: remify(15, 16);
        font-weight: 700;
        margin: 0;
        padding: remify(15 30, 15);
    }
}

.panel-toggle_icon {
    & {
        flex: 0 0 auto;
        font-size: remify(30, 15);
        margin-right: remify(15, 30);
    }

    .icon_svg {
        bottom: auto;
    }
}
