// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Link
\* ------------------------------------------------------------------------ */

.link {
    & {
        color: $accent;
        cursor: pointer;
        text-decoration: none;
        transition: color 0.15s;
    }

    &:focus,
    &:hover {
        color: $accent_alt;
    }
}

/* inherit variant */

.link.-inherit {
    & {
        color: inherit;
    }

    &:focus,
    &:hover {
        color: inherit;
        text-decoration: underline;
    }
}

/* primary variant */

.link.-primary {
    & {
        color: $primary;
    }

    &:focus,
    &:hover {
        color: $primary_alt;
    }
}

/* tertiary variant */

.link.-tertiary {
    & {
        color: $tertiary;
    }

    &:focus,
    &:hover {
        color: $tertiary_alt;
    }
}

/* quaternary variant */

.link.-quaternary {
    & {
        color: $quaternary;
    }

    &:focus,
    &:hover {
        color: $quaternary_alt;
    }
}
