// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Article
\* ------------------------------------------------------------------------ */

.article_figure {
    & {
        margin: remify(0 0 30, 16);
    }
}

.article_image {
    & {
        display: block;
        width: 100%;
    }
}

/* eventexcerpt variant */

.article.-eventexcerpt {
    & {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath fill='%23D6D6D6' d='M28.3,16c0-1-0.8-1.8-1.8-1.8S24.6,15,24.6,16c0,1,0.8,1.8,1.8,1.8C27.5,17.8,28.3,17,28.3,16z M20.3,16 c0-1-0.8-1.8-1.8-1.8c-1,0-1.8,0.8-1.8,1.8c0,1,0.8,1.8,1.8,1.8C19.4,17.8,20.3,17,20.3,16z M12.2,16c0-1-0.8-1.8-1.8-1.8 S8.5,15,8.5,16c0,1,0.8,1.8,1.8,1.8S12.2,17,12.2,16z M4.2,16c0-1-0.8-1.8-1.8-1.8S0.5,15,0.5,16c0,1,0.8,1.8,1.8,1.8 C3.3,17.8,4.2,17,4.2,16z' /%3E%3C/svg%3E%0A");
        background-position: left bottom;
        background-repeat: repeat-x;
        background-size: remify(21.62746688294134 21.62746688294134);
        padding: remify(0 15 10);
    }

    .article_text {
        font-range: $xs-break $xl-break;
        font-size: responsive remify(12, 16) remify(15, 16);
    }
}

/* excerpt variant */

.article.-excerpt {
    &:not(:last-of-type) {
        border-bottom: remify(1) solid darken($background_alt, 10);
        margin-bottom: remify(20, 16);
    }

    .article_title {
        font-size: remify(18, 16);
    }

    .article_header > .article_title:not(:last-child) {
        margin-bottom: 0;
    }

    .article_header > .article_title:not(:last-child) + * {
        margin-bottom: remify(10, 16);
    }
}

/* material variant */

.article.-material {
    & {
        margin-bottom: remify(12, 16);
    }
}
