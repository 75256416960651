// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Search
\* ------------------------------------------------------------------------ */

.search-block_container {
    @media screen and (max-width: $l-break - (1 / 16)) {
        & {
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.15s, visibility 0s 0.15s;
            visibility: hidden;
        }

        &.is-active {
            max-height: remify(160, 16);
            transition: max-height 0.15s;
            visibility: visible;
        }
    }
}

.search-block {
    & {
        @include layer("search");
    }
}

.search_inner {
    & {
        background: $primary;
        margin: 0 auto;
        max-width: remify($site-width);
        padding: remify(($content-padding * 0.4) ($content-padding * 0.27));
    }

    &.-narrow {
        max-width: remify($site-width * 0.75);
    }
}

/* fullbleed variant */

.search-block.-fullbleed {
    & {
        background: $primary;
        padding: remify(($content-padding * 0.4) ($content-padding * 0.27));
    }

    > .search_inner {
        background: none;
        padding: 0;
    }

    > .search_inner.-fullbleed {
        max-width: none;
    }
}
