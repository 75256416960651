// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Title
\* ------------------------------------------------------------------------ */

/* stylelint-disable selector-class-pattern */

.title {
    &,
    &.-h1,
    &.-h2 {
        color: $primary;
        font-display: swap;
        font-family: $heading-font;
        font-range: $xs-break $xl-break;
        font-size: responsive remify(25, 16) remify(32.87, 16);
        font-weight: 700;
        line-height: remify(31.25, 25);
        margin: remify(0 0 10, 25);
        text-transform: uppercase;
    }

    &.-h3,
    &.-h4 {
        font-range: $xs-break $xl-break;
        font-size: responsive remify(17.5, 16) remify(22, 16);
        line-height: remify(21.875, 17.5);
        margin: remify(0 0 8, 17.5);
    }

    &.-h5,
    &.-h6 {
        font-family: $body-font;
        font-range: $xs-break $xl-break;
        font-size: responsive remify(12, 16) remify(16, 16);
        font-weight: 700;
        line-height: remify(16.5, 12);
        margin: 0;
        text-transform: none;
    }
}

/* primary variant */

.title.-primary {
    & {
        color: $primary;
    }
}

/* secondary variant */

.title.-secondary {
    & {
        color: $secondary;
    }
}

/* tertiary variant */

.title.-tertiary {
    & {
        color: $tertiary;
    }
}

/* quaternary variant */

.title.-quaternary {
    & {
        color: $quaternary;
    }
}

/* dark_alt variant */

.title.-dark_alt {
    & {
        color: $dark_alt;
    }
}

/* divider variant */

.title.-divider {
    & {
        align-items: center;
        display: flex;
    }

    &::before,
    &::after {
        background: darken($background_alt, 10);
        content: "\0020";
        display: block;
        flex: 1 1 0%;
        height: remify(1);
    }

    &::before {
        margin-right: remify(16, 16);
    }

    &::after {
        margin-left: remify(16, 16);
    }
}
