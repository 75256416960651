// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Widget
\* ------------------------------------------------------------------------ */

/* stylelint-disable selector-class-pattern */

.widget {
    & {
        background: $background;
        border-radius: remify(3);
        margin: remify(0 0 30);
        overflow: hidden;
    }
}

.widget_figure {
    & {
        margin: 0;
    }

    .widget_image {
        display: block;
        width: 100%;
    }
}

.widget_content {
    & {
        border-top: remify(5) solid $primary;
        padding: remify(($content-padding * 0.3) ($content-padding * 0.45));
    }
}

/* primary variant */

.widget.-primary {
    .widget_content {
        border-top-color: $primary;
    }
}

/* secondary variant */

.widget.-secondary {
    .widget_content {
        border-top-color: $secondary;
    }
}

/* tertiary variant */

.widget.-tertiary {
    .widget_content {
        border-top-color: $tertiary;
    }
}

/* quaternary variant */

.widget.-quaternary {
    .widget_content {
        border-top-color: $quaternary;
    }
}

/* dark_alt variant */

.widget.-dark_alt {
    .widget_content {
        border-top-color: $dark_alt;
    }
}
